.main-card {
    width: 370px;
    height: 550px;
    padding-left: 1rem;
    background-color: var(--background-color-2);
    position: relative;
    overflow: hidden;
    font-family: var(--font-body-family);
}
.image-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container:hover .card-img {
    transform: scale(1.1); /* Zoom effect */
}


.card-img {
    height: 400px;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
}
.card-img:hover{
    transform: scale(1.1); /* Zoom effect */
}

.card-title {
    font-weight: 500;
    font-size: 1em;
    text-transform: uppercase;
    color: var(--secondary-text-color);
    cursor: pointer;
}
.card-discount-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red; /* Highlight with a color */
    color: white;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    z-index: 10;
}

.card-price-with-discount {
    font-size: 1.2em;
    color: var(--secondary-text-color);
    font-weight: 700;
}

.card-title-stock{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-price-with-discount-div{
    margin-top: -1.5rem;
}

.card-stock-instock{
    font-size: 0.9em;
    border-radius: 23px;
    color: var(--primary-text-color);
    font-weight: 600;
    background-color: var(--green-color);
    text-transform: uppercase;
    width: 100%;
    padding: .3rem .8rem;
}

.card-stock-outstock{
    font-size: 0.9em;
    border-radius: 23px;
    color: var(--primary-text-color);
    font-weight: 600;
    background-color:#F5374C;
    text-transform: uppercase;
    padding: .3rem .8rem;
}

.main-card:hover .card-title {
    text-decoration: underline;
}

.view-detail-cart-btn {
    padding: 0;
    width: 94%;
    height: 40px;
    opacity: 0;
    transform: translateY(20px);
}

.view-btn {
    position: absolute;
    top: 60%;
    background-color: var(--background-color-2);
    width: 100%;
    opacity: 0;
    /* padding: 0.25rem; */
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.main-card:hover .view-detail-cart-btn {
    opacity: 1;
    transform: translateY(0);
    transition: transform 1s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.main-card:hover .view-btn {
    opacity: 1;
    transition: all ease-in-out 0.5s;
}

.card-img:hover {
    opacity: 1;
}

.card-discount-price-div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    margin-top: -2.25rem;
}

.card-discount-only-price-div{
    margin-top: -1.75rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* .main-card-features {
    position: absolute;
    top: 40%;
    left: 62.5%;
    transform: translate(-50%, -50%);
    gap: 1rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.main-card-features-svg {
    color: var(--secondary-text-color);
    background-color: var(--hover-color);
    border-radius: 100%;
    padding: 0.5rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.main-card-features-svg-disable{
    color: var(--secondary-text-color);
    background-color: var(--grey-text-color);
    border-radius: 100%;
    padding: 0.5rem;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.card-feature-img-text-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 150px;
}

.feature-text {
    color: black;
    font-size: 0.9em;
    background-color: var(--primary-text-color);
    padding: 0.25rem;
    border-radius: 5px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.main-card-features-svg:hover~.feature-text {
    opacity: 1;
    transform: translateY(0);
}

.card-feature-img-text-div:hover .feature-text {
    opacity: 1;
    transform: translateY(0);
}

.main-card-features-svg:hover {
    background-color: red;
    transform: scale(1.1);
}

.main-card:hover .main-card-features {
    opacity: 1;
}

.main-card:hover .card-img {
    opacity: 0.5;
} */

.rating-cart {
    margin-top: -1rem;
}

@media (max-width: 850px) {
    .image-container {
        all: unset;  /* Resets all styles */
    }
    .main-card {
        width: 240px;
        height: 450px;
    }

    .view-detail-cart-btn {
        width: 92%;
    }

    .view-btn {
        top: 55%;
    }
}

@media (max-width:550px) {
    .image-container {
        all: unset;  /* Resets all styles */
    }
    .card-discount-label {
        position: absolute;
        top: 9px;
        right: 10px;
        background-color: red;
        color: white;
        padding: 3px 10px;
        font-size: 8px;
        font-weight: bold;
        border-radius: 5px;
    }
    .card-title {
        font-size: 0.8em;
        text-transform: uppercase;
        color: var(--secondary-text-color);
        cursor: pointer;
    }
        .main-card {
            width: 48%; /* Adjust width to fit two cards side by side */
            height: auto; /* Adjust height as needed */
            padding: 0;
        }
    
    .card-img {
        height: 200px;
        width: 100%;
        transition: opacity 0.5s ease-in-out;
    }

    .main-card-features {
        position: absolute;
        top: 40%;
        left: 70%;
    }

    .view-detail-cart-btn {
        width: 100%;
    }
}

@media (max-width:450px) {
    .image-container {
        all: unset;  /* Resets all styles */
    }
    .card-title {
        font-size: 0.8em;
        text-transform: uppercase;
        color: var(--secondary-text-color);
        cursor: pointer;
    }
        .main-card {
            width: 48%; /* Adjust width to fit two cards side by side */
            height: auto; /* Adjust height as needed */
            padding: 0;
        }
    .main-card-features {
        position: absolute;
        top: 40%;
        left: 68%;
    }
    .card-img {
        height: 250px;
        width: 100%;
        transition: opacity 0.5s ease-in-out;
    }
}

/* @media (min-width : 1400px) {
    .main-card {
        width: 300px;
    }
} */