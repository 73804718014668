.main-all-products{
    background-color: var(--background-color-2);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
    padding-top: 4rem;
    font-family: var(--font-body-family);
}

.heading-allproducts{
    font-size: 2.5rem;
    text-transform: uppercase;
    color: var(--primary-text-color);
    text-align: center;
}

@media (max-width:550px){
    .main-all-products{
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}